.app {
    font-family: 'Poppins';
}

.suspense {
    padding: 1rem;
    padding-top: 5rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
}

.home {
    background-image: url('./assets/fondo.webp');
    background-position: 10% 35%;
    background-size: cover;
}

@media (max-width: 600px) {
    .home {
      background-position: 20% 65%;
      background-size: cover;
    }
}

@media (max-width: 375px) {
    .logo {
        bottom: -3rem;
    }
}

.model-viewer {
    height: 780px!important;
}